/* eslint-disable no-console */
import * as Sentry from '@sentry/vue';
import LogRocket from 'logrocket';
import { VITE_GIT_BRANCH, VITE_GIT_COMMIT } from '../Configuration.ts';

export async function getLogrocketSessionUrl() {
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.getCurrentScope().setTag('logrocket', sessionURL);
  });
}

export async function initializeLogrocket(force = false) {
  if (VITE_GIT_BRANCH !== 'main' && !force) return;
  try {
    LogRocket.init('mcarxn/pando', { release: VITE_GIT_COMMIT });
    await getLogrocketSessionUrl();
  } catch (e) {
    console.error('LogrocketError: initialize failed:', e);
  }
}

export function identifyLogrocket(employee, force = false) {
  if (VITE_GIT_BRANCH !== 'main' && !force) return;
  try {
    LogRocket.identify(employee.email);
  } catch (e) {
    console.error('LogrocketError: identify failed:', e);
  }
}
