<script setup>
import { inject } from 'vue';

const navSpec = inject('navSpec');
const headerSpec = inject('headerSpec');

// fixedMinHeight takes up the whole screen unless there's additional content, in which case it scrolls
defineProps({ fixedHeight: { type: Boolean, default: false }, fixedMinHeight: { type: Boolean, default: false } });
</script>

<template>
  <main
    class="pando__one-column"
    :style="(fixedHeight && { height: `calc(100vh - (${navSpec.height + headerSpec.height}px + 3.5rem)`, gap: 0 }
      || (fixedMinHeight && { 'min-height': `calc(100vh - (${navSpec.height + headerSpec.height}px + 3.5rem)`, gap: 0 }))"
  >
    <slot />
  </main>
</template>

<style lang="postcss">

.pando__one-column {
  display: flex;
  justify-content: center;
  margin-bottom: var(--layout-gap-jumbo);
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: visible;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  & *,
  & *::before,
  & *::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
</style>
