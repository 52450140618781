const scrollIntoView = {
  mounted(el: HTMLElement, binding: any) {
  // Looks like there is no other way in Vue to make sure that element is in the DOM
    function scrollIntoViewAfterRender() {
    // check if element is visible
      if (!el.offsetParent) {
        window.requestAnimationFrame(scrollIntoViewAfterRender);
      } else {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }

    if (binding.value) scrollIntoViewAfterRender();
  },
};

export default scrollIntoView;
