<script setup>
import { useResizeObserver } from '@vueuse/core';
import { inject, ref, reactive, watch } from 'vue';

const navSpec = inject('navSpec');
const headerSpec = inject('headerSpec');

defineProps({ hasMobileSelection: { type: Boolean, default: false } });

const inboxHeaderElm = ref(null);
const inboxHeaderspec = reactive({ height: 0, width: 0 });

watch(inboxHeaderElm, async () => {
  if (inboxHeaderElm.value) {
    try {
      useResizeObserver(inboxHeaderElm.value, (entries) => {
        const [entry] = entries;
        const { width, height } = entry.contentRect;

        // setTimeout allow the ResizeObserver loop to finish
        // before this effect modifies the observed element.
        // This prevents the error "ResizeObserver loop completed with undelivered notifications"
        setTimeout(() => {
          inboxHeaderspec.width = width;
          inboxHeaderspec.height = height;
        }, 0);
      });
    } catch (e) {
      /* eslint-disable-next-line */
      console.log('error: ', e);
      inboxHeaderspec.width = 0;
      inboxHeaderspec.height = 0;
    }
  }
});

</script>

<template>
  <main class="pando__inbox-layout">
    <div
      ref="inboxHeaderElm"
      class="e-header"
    >
      <slot name="header" />
    </div>
    <div
      class="e-inbox-content"
      :style="{ height: `calc(100vh - (${navSpec.height + headerSpec.height + inboxHeaderspec.height}px + 3.5rem)`}"
    >
      <div class="e-left-column">
        <slot name="left" />
      </div>
      <div
        class="e-right-column"
        :class="{'m-item-selected': hasMobileSelection}"
      >
        <slot name="right" />
      </div>
    </div>
  </main>
</template>

<style lang="postcss">
.pando__inbox-layout {
  display: flex;
  justify-content: center;
  margin: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  & *,
  & *::before,
  & *::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  & .e-header {
    width: 100%;
  }

  & .e-inbox-content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    height: 100%;

    & .e-left-column {
      position: relative;
      flex: 2 1 200px;
      display: flex;
      flex-direction: column;
    }

    & .e-right-column {
      position: relative;
      flex: 3 1 300px;
      display: flex;
      flex-direction: column;
      min-width: 0;
    }
  }

  @media (max-width: 940px) {
    & .e-inbox-content {
      position: relative;

     & .e-right-column {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;

        &.m-item-selected {
          z-index: 2;
        }
      }
    }
  }

}</style>
