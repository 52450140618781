<script setup lang="ts">
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import { computed, reactive, ref, provide, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = reactive(useRoute());
const title = computed(() => router.currentRoute.value.meta.title);
const appBackgroundColor = ref('');
const appBackgroundClass = computed(() => (route.meta.background ? `m-${route.meta.background || 'default'}-bg` : `m-${appBackgroundColor.value || 'default'}-bg`));
const currentPath = computed(() => router.currentRoute.value.path);

watch(title, () => { document.title = title.value ? `Pando | ${title.value}` : 'Pando'; });
provide('currentPath', currentPath);
provide('appBackgroundClass', appBackgroundClass);
provide('appBackgroundColor', appBackgroundColor);
</script>

<template>
  <div :class="appBackgroundClass">
    <RouterView />
    <div
      class="visually-hidden-text"
      aria-live="assertive"
      aria-atomic="true"
    >
      {{ title }}
    </div>
  </div>
</template>

<style lang="postcss">
@import "./styles/common/pando-default.css";
@import "./styles/templates/visually-hidden.css";
@import "./styles/common/tooltip.css";

#app {
  @media (max-width: 600px) {
    overflow-y: auto;
  }
  & .m-default-bg {
    background-color: var(--default-app-bg-color);
  }
  & .m-peer-bg {
    background-color: var(--peer-app-bg-color);
  }
  & .m-dark-bg {
    background-color: var(--dark-app-bg-color);
    & .e-breadcrumbs,
    & .e-page-header .pando__title {
        color: white;
    }
  }
}
</style>
