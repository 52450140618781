import DOMPurify from 'dompurify';

const sanitize = {
  mounted(el: HTMLElement, { oldValue, value }:any) {
    const config = {
      ALLOWED_TAGS: ['span', 'br'],
      ALLOWED_ATTR: ['class'],
    };

    if (value !== oldValue) {
      // eslint-disable-next-line no-param-reassign
      el.innerHTML = DOMPurify.sanitize(value, config);
    }
  },

};

export default sanitize;
