import { createPopper } from '@popperjs/core';
import type { Instance } from '@popperjs/core';

interface TooltipData {
  content: string,
  showListener: any,
  hideListener: any,
  interactive: HTMLElement | null
}

let pandoTooltipInstance:Instance;

const tooltipLocalStorage:{[key: string]: TooltipData} = {};

const TOOLTIP_ID = 'pando-tooltip';
const TOOLTIP_PARAGRAPH_ID = 'pando-tooltip-paragraph';

const pandoTooltip = {
  mounted(el:HTMLElement, binding: any) {
    /* create our binding storage first */
    const bindingIds = Object.keys(tooltipLocalStorage).map(Number);
    const currentElementBindingId = bindingIds.length ? Math.max(...bindingIds) + 1 : 0;

    /* get interactive elements */
    const elTagName: string = el.tagName.toString();
    const isInteractiveElement: boolean = ['BUTTON', 'A', 'INPUT', 'TEXTAREA', 'SELECT'].includes(elTagName);
    const interactiveElements: HTMLElement | null = el.querySelector('button, input, a, textarea, select');
    if (interactiveElements) interactiveElements.setAttribute('aria-description', binding.value);
    el.setAttribute('aria-description', binding.value);

    /* get tooltip element */
    const pandoTooltipEl = document.getElementById(TOOLTIP_ID);
    const pandoTooltipParagraphEl = document.getElementById(TOOLTIP_PARAGRAPH_ID);

    /* returns an array of child interactive elements
    * so we can ignore these on click off element events */
    function getInteractiveTooltipData(): HTMLElement[] {
      if (!tooltipLocalStorage) return [];
      return Object.values(tooltipLocalStorage).reduce((acc: HTMLElement[], cur: TooltipData) => {
        if (cur.interactive) {
          acc.push(cur.interactive);
        }
        return acc;
      }, []);
    }

    function hide() {
      if (!pandoTooltipEl) return;
      pandoTooltipEl.removeAttribute('data-show');
    }

    /* if focus moves elsewhere, hide elements */
    function ifFocusOutside(event:any) {
      const interactiveItems = getInteractiveTooltipData();
      if (event.target !== el && !interactiveItems.includes(event.target)) {
        hide();
        pandoTooltipInstance.state.elements.reference = el;
        pandoTooltipInstance.update();
      }
    }

    /* create popper instance */
    if (!pandoTooltipInstance && pandoTooltipEl) {
      /* we will always watch for focusin in #app, since these bubble */
      const appEl = document.getElementById('app');
      appEl?.addEventListener('focusin', ifFocusOutside);
      pandoTooltipInstance = createPopper(el, pandoTooltipEl, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: { offset: [0, 12] },
          },
        ],
      });
    }

    function show() {
      /* if tooltip isn't available stop */
      if (!pandoTooltipEl || !pandoTooltipParagraphEl || !tooltipLocalStorage[currentElementBindingId].content) return;
      /* get inner paragraph element */
      pandoTooltipParagraphEl.innerHTML = tooltipLocalStorage[currentElementBindingId].content;
      pandoTooltipEl.setAttribute('data-show', '');
      pandoTooltipInstance.state.elements.reference = el;
      pandoTooltipInstance.update();
    }

    /* bind event listenres to wrapper element */
    el.addEventListener('mouseenter', show);
    el.addEventListener('mouseleave', hide);

    /* and any inner interactive elements */
    if (interactiveElements) {
      interactiveElements.addEventListener('focus', show);
      interactiveElements.addEventListener('blur', hide);
    }
    if (isInteractiveElement) {
      el.addEventListener('focus', show);
      el.addEventListener('blur', hide);
    }

    // Saving the value and setting a tooltip id so we can
    // correctly update the tooltip if the component is updated
    // with a new tooltip text (or to remove the tooltip by setting
    // it to null, e.g. Competencies picker on Add Goal form)
    tooltipLocalStorage[currentElementBindingId.toString()] = {
      content: binding.value,
      showListener: show,
      hideListener: hide,
      interactive: isInteractiveElement ? el : interactiveElements,
    };

    el.setAttribute('data-tooltip-id', currentElementBindingId.toString());
  },

  updated(el:HTMLElement, binding: any) {
    const elTooltipId = el.getAttribute('data-tooltip-id');
    const newBindingValue = elTooltipId ? tooltipLocalStorage[elTooltipId].content : null;
    if (elTooltipId && newBindingValue !== binding.value) tooltipLocalStorage[elTooltipId].content = binding.value;
  },

  beforeUnmount(el:HTMLElement) {
    const elTooltipId = el.getAttribute('data-tooltip-id');
    if (elTooltipId && tooltipLocalStorage[elTooltipId]) {
      tooltipLocalStorage[elTooltipId].hideListener();
      el.removeEventListener('mouseenter', tooltipLocalStorage[elTooltipId].showListener);
      el.removeEventListener('mouseleave', tooltipLocalStorage[elTooltipId].hideListener);
      tooltipLocalStorage?.[elTooltipId]?.interactive?.removeEventListener('focus', tooltipLocalStorage[elTooltipId].showListener);
      tooltipLocalStorage?.[elTooltipId]?.interactive?.removeEventListener('blur', tooltipLocalStorage[elTooltipId].hideListener);
      delete tooltipLocalStorage[elTooltipId];
    }
  },
};

export default pandoTooltip;
